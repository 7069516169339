import React,  { Fragment, useState, useEffect } from 'react';
import { useLocation, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';
import moment from "moment";

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import CustomContainer from "../components/CustomContainer";
import Loader from "../components/Loader";
import RippleButton from "../components/RippleButton";
import OrderSheet from "../components/OrderSheet";
import DataTable from "../components/DataTable";
import Filler from "../components/Filler";
import ConfirmationDialogModal from "../components/ConfirmationDialogModal";

import SaveOrderModal from "../components/SaveOrderModal";
import AccountBarTabModal from "../components/AccountBarTabModal";

import API from "../services/backend-api";

import { useConfig } from "../services/use-config";
import { useAuth } from "../services/use-auth";

import { getAllFiles, isArrayWithLength } from "../helpers/commons";
import useSound from 'use-sound';

import deleteOrderAudio from '../audios/ok-houve-um-equivoco.mp3';

import Confetti from 'react-confetti';


const rippleButtonStyle = { /*position: "fixed", top: "40%", left: "40%",*/ fontSize: "1.5rem", fontWeight: "600"};

const dataTableActionModals = [
	{
        key: 'action-0',
        component: SaveOrderModal,
		size: 'lg',
		multiple : false
      },
		{
        key: 'action-1',
        component: AccountBarTabModal,
		size: 'lg',
		multiple : false
      },
		{
        key: 'action-2',
        component: Filler,
		size: 'lg',
		multiple : false
      },
	  {
        key: 'multiple-action-0',
        component: Filler,
		size: 'lg',
		multiple : true
      }
];


const OrderSheets = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const [orderSheetItems, setOrderSheetItems] = useState(null);
	const [products, setProducts] = useState([]);
	const [birthdays, setBirthdays] = useState([]);
	const [availableOrderedProducts, setAvailableOrderedProducts] = useState([]);
	const [eventDate, setEventDate] = useState(moment(new Date()));
	const [displayMode, setDisplayMode] = useState("account-orders");
	//const [fontSize, setFontSize] = useState([]);
	const [selectedDataTableItem, setSelectedDataTableItem] = useState(null);
	const [selectedDataTableItems, setSelectedDataTableItems] = useState(null);
	const [dataTableActionModalsShowMap, setDataTableActionModalsShowMap] = useState(new Map());
	const [runConfetti, setRunConfetti] = useState(false);
	
	const [memes, setMemes] = useState([]);
	const [mockups, setMockups] = useState(new Map());
	
	const location = useLocation();
	//let history = useNavigate();
	const config = useConfig();
	const auth = useAuth();
	const { t } = useTranslation()
	
	let context = useOutletContext();
	
	const [playDeleteOrderAudio] = useSound(deleteOrderAudio);
	
	/*let currency = Intl.NumberFormat(config.preferredLanguage, {
    	style: "currency",
    	currency: config.numberFormat.currency,
	});*/
	
	useEffect(() => {
		let isMounted = true; 
		
		if (location.state && location.state.success)
			context.onSuccess(location.state.success)
			
		//Initializing Action Modals Show Status
		resetModalsVisibility();
			
		//Import all Memes
		let audioFiles = getAllFiles(require.context('../audios/memes', false, /\.(mp3|mp4|wav|ogg|webm|aac)$/));
		setMemes(loadMemes(audioFiles));
		
		//Import all Mockups
		let imageFiles = getAllFiles(require.context('../images/mockups', false, /\.(png|jpe?g|svg|webp)$/));
		setMockups(loadMockups(imageFiles));
		
		findProducts({})
			.then(response => {
				
				if (isMounted) 
					setAvailableOrderedProducts(response.list.filter(item => (JSON.parse(item.available)))
						.sort((a,b) => (parseInt(a.shelfDisplayOrder) === 0)
							? 1 
							: (parseInt(b.shelfDisplayOrder) === 0)
								? -1
								: parseInt(a.shelfDisplayOrder)-parseInt(b.shelfDisplayOrder)));
						
			
				findOrderSheetItems(eventDate)
					.catch(error => { 
		 				log.error("Error Loading Initial Orders List: ", error.message);
		 				context.onError(error);
					})
			})
			.catch(error => { 
		 		log.error("Error Loading Products List: ", error.message);
		 		context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
			});
			
			
		findBirthdays(eventDate)
			.then(response => {
				if (isMounted) 
					setBirthdays(response.list);
						
			})
			.catch(error => { 
		 		log.error("Error Loading Birthdays List: ", error.message);
		 		context.onError(error);
			})
		 
		 return () => { isMounted = false };
		  
	}, []);

  const resetModalsVisibility = () => {
	let tempMap = new Map();
	dataTableActionModals.forEach((item, index) => {
		tempMap.set(item.key, false);
	});
	setDataTableActionModalsShowMap(tempMap);
  }
	
	const loadMemes = (files) => {
		let memes = [];
  		Object.keys(files).forEach((k) => { memes.push(new Audio(files[k])) });
		return memes
	}
	
	const loadMockups = (files) => {
 		let mockups = new Map();
		Object.keys(files).forEach((k) => { mockups.set(k.replace(/\.[^/.]+$/, ""),files[k])});
 		return mockups
	}


  	const handleActionModalHide = () => {
	  resetModalsVisibility();
	  setSelectedDataTableItem(null); 
      setSelectedDataTableItems(null);
	  
    }

	const showActionModal = (action) => { 
	  let tempMap = dataTableActionModalsShowMap;
	  tempMap.set(action, true);
	  setDataTableActionModalsShowMap(tempMap);
   }

    const handleItemsSaved = (success) => {
	  handleActionModalHide();
	  refreshPage();
	  //context.onSuccess(success);
	  playSuccessfullAudio();
	  setRunConfetti(true);
   }

	const playSuccessfullAudio = () => {
		let r = Math.floor(Math.random() * memes.length);
		memes[r].play();
   }

  const handleAccountOrderAddition = (item) => {
	  let accountOrder = {}; 
	  accountOrder.eventDate = eventDate.format("YYYY-MM-DD");
	  accountOrder.accountNo = (item.accountNo) ? item.accountNo : "";
	  accountOrder.customerDisplayName = (item.customerDisplayName) ? item.customerDisplayName : "";
	  accountOrder.productId = (item.productId) ? item.productId : "";
	  accountOrder.sellingPrice = (item.sellingPrice) ? item.sellingPrice : "";

	  addOrder(accountOrder);
  }

  const handleProductOrderAddition = (item) => {
	  let productOrder = {}; 
	  productOrder.eventDate = eventDate.format("YYYY-MM-DD");
	  productOrder.accountNo = (item.accountNo) ? item.accountNo : "";
	  productOrder.customerDisplayName = (item.customerDisplayName) ? item.customerDisplayName : "";
	  productOrder.productId = (item.productId) ? item.productId : "";
	  productOrder.sellingPrice = (item.sellingPrice) ? item.sellingPrice : "";

	  addOrder(productOrder);
  }

  const handleToolbarOrderAddition = (item) => {
	  let order = {}; 
	  order.eventDate = eventDate.format("YYYY-MM-DD");

	  addOrder(order);
  }
  
  const addOrder = (values) => {
	  let item = {};  
	  
	  if (values) {
		  for(var property in values) {
				if(values.hasOwnProperty(property)) {
					item[property] = values[property];
				}
			}
	  }
	  
	  setSelectedDataTableItem(item);
	  showActionModal("action-0")
  }

    
  /*const updatePage = (values) => {
	  findOrderSheetItems(values)
		.catch(error => { 
			log.error("Error Paginating Orders List: ", error.message);
	 		context.onError(error);
		})
  }*/
  
  /*const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  findOrderSheetItems(values)
		.catch(error => { 
			log.error("Error Sorting Orders List: ", error.message);
	 		context.onError(error);
		})
		
  }*/
  
 const handleEventDateChange = (newEventDate) => {
		  
      let currentEventDate = eventDate;

	  setIsRefreshing(true);
	  setEventDate(newEventDate);

	  //If month has changed, we must update birthday
	  if (currentEventDate.month() !== newEventDate.month()) {
			findBirthdays(newEventDate)
			.then(response => {
				setBirthdays(response.list);		
			})
			.catch(error => { 
		 		log.error("Error Refreshing Birthdays List: ", error.message);
		 		context.onError(error);
			})
	  }
	  
	  //List Orders with no new values
	  findOrderSheetItems(newEventDate)
		.catch(error => { 
	 		log.error("Error Refreshing Orders List: ", error.message);
	 		context.onError(error);
		}).finally(() => {
		 	setIsRefreshing(false);
		});	
  }  

const handleDisplayModeChange = (mode) => {
	setDisplayMode(mode);
}

/*const handleFontSizeChange = (size) => {
	setFontSize(size);
	
	var cont = document.getElementsByClassName("container");
	if (isArrayWithLength(size)) {
       // We doubled Font Size
		Array.from(cont).forEach((el) => {
    		el.style.fontSize = "2em";
	    });
	} else {
		// We make it half size
		Array.from(cont).forEach((el) => {
    		el.style.fontSize = "1em";
	    });
	}
}*/

const isToday = () => {
	  
	  return (eventDate.format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD"));
    }  
  
 const refreshPage = () => {
		  
	  //List Orders with no new values
	  findOrderSheetItems(eventDate)
		.catch(error => { 
	 		log.error("Error Refreshing Orders List: ", error.message);
	 		context.onError(error);
		})
  }  

  const findOrderSheetItems = (date) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.findOrders({filteredColumn: "eventDate", filter: date.format("YYYY-MM-DD"), sortBy: "whenModified"}).then(response => {
			
			setOrderSheetItems(response);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
  const findProducts = () =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
		
		API.findProducts({}).then(response => {
			setProducts(response.list);
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	const findBirthdays = (date) =>
		new Promise((resolve, reject) => {
		
		API.findAccounts({filteredColumn: "birthMonth", filter: date.format("YYYY-MM-DD")}).then(response => {
			resolve(response);
		}).catch(error => {			
			reject(error);
		});
	});
	
	const confirmOrderDeletion = (item, action) => {
	  setSelectedDataTableItem(item);
	  showActionModal("action-2")
   }

	
   /*const showAccountOrdersPreview = (item, action) => {
	  setSelectedDataTableItem(item);
	  showActionModal("action-1")
   }*/

	const showAccountBarTab = (item, index) => {
	  let accountBarTab = {}; 
	  accountBarTab.eventDate = eventDate.format("YYYY-MM-DD");
	  accountBarTab.accountNo = item.accountNo;
	  accountBarTab.customerDisplayName = item.customerDisplayName;
	  setSelectedDataTableItem(accountBarTab);
	  showActionModal("action-1")
   }

   /* const confirmMultipleOrdersDeletion = (items, action) => {

	  setSelectedDataTableItems(items);
	  showActionModal(action)
    }*/
	
   const deleteOrder = (item) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		API.deleteOrder(item.id, auth.isAdmin(), config.csrfToken).then(response => {
			context.onSuccess(response.success);
			playDeleteOrderAudio();
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing Order: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }

  /*const deleteMultipleOrders = (items) => {
		
	  	//Clear Error/Success
		context.onError(null);
		context.onSuccess(null);
		
		let listOfItemsId = items.map(e => Object.assign({}, {"id": e.id}));
		
		API.deleteMultipleOrders(listOfItemsId,  config.csrfToken).then(response => {
			context.onSuccess(t('orders.delete-multiple-orders-confirmation-modal.success'));
			refreshPage();
		}).catch(error => { 	
			log.error("Error Removing Orders: ", error.message);
			context.onError(error);		
		}).finally(() => {
			handleActionModalHide();
		});
  }*/

   	if (isLoading) 
		return <Loader />
	
	if (!orderSheetItems) 
		return null;
		
	return (
		<CustomContainer>
			{isRefreshing ? <Loader show={isRefreshing}/> : null}
			{runConfetti ? <Confetti recycle={false} onConfettiComplete={() => {setRunConfetti(false);}}/> : null }
			{ dataTableActionModals.map((item, index) => {
								
				const { 
		    		component: Component, 
		    		...rest 
		    	} = item;
			
				return (
					<Fragment key={item.key}>
					{ (dataTableActionModalsShowMap.get(item.key)) ?
						<Fragment>
							{(item.multiple && selectedDataTableItems) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="order-sheets"
									items={selectedDataTableItems}
									onError={context.onError}
									onItemsSaved={handleItemsSaved}
									products={products}
									mockups={mockups}
								/>
								: 
								(!item.multiple && selectedDataTableItem) ?
								<Component 
									show={dataTableActionModalsShowMap.get(item.key)} 
									onHide={handleActionModalHide}
									size={item.size}
									origin="order-sheets"
									item={selectedDataTableItem}
									onError={context.onError}
									onItemSaved={handleItemsSaved}
									products={availableOrderedProducts}
									mockups={mockups}
								/>
								:
								null
							}
						</Fragment>
						:
						null
					}
					</Fragment>
	      		)
    		})}
			
			{/*(selectedDataTableItem && dataTableActionModalsShowMap.get("action-1")) && <ShowDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-1")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("ordersheets.account-orders-preview-modal.title")}
				bodyText={t("ordersheets.account-orders-preview-modal.body", {item: Object.assign({},selectedDataTableItem, { customerBillFormatted: currency.format(selectedDataTableItem.customerBill)})})}
				closeText={t("ordersheets.account-orders-preview-modal.close")}
				variant="info"
			/>*/}
			
			{(selectedDataTableItem && dataTableActionModalsShowMap.get("action-2")) && <ConfirmationDialogModal
				item={selectedDataTableItem}
				show={dataTableActionModalsShowMap.get("action-2")}
        		onHide={handleActionModalHide}
				size="lg"
				title={t("orders.delete-order-confirmation-modal.title")}
				bodyText={t("orders.delete-order-confirmation-modal.body", {item: selectedDataTableItem})}
				confirmText={t("orders.delete-order-confirmation-modal.confirm")}
				cancelText={t("orders.delete-order-confirmation-modal.cancel")}
				variant="danger"
				onConfirmation={deleteOrder}
			/>}

		<OrderSheet.Toolbar
			i18nPrefix="ordersheets.list.toolbar."
			eventDate={eventDate}
			variant="outline-primary"
			onEventDateChange={handleEventDateChange}
			onDisplayModeChange={handleDisplayModeChange}
			displayMode={displayMode}
			//fontSize={fontSize}
			//onFontSizeChange={handleFontSizeChange}
			onOrderAddition={handleToolbarOrderAddition}
			
		/>
		
		{(displayMode === "account-orders") ?
			<OrderSheet.DataTable
				i18nPrefix="ordersheets.list.datatable."
				items={orderSheetItems.list}
				onError={context.onError}
				products={products} 
				actions={[handleAccountOrderAddition, showAccountBarTab]}
				birthdays={birthdays}
				onAccountOrderAddition={handleAccountOrderAddition}
				onProductOrderAddition={handleProductOrderAddition}
				multipleSelectActions={[]}
				//handleTextOverflow={true}
				showTotalRow={auth.isAdmin()}
				noItemsDisclaimer={true}
				mockups={mockups}
			/>
			: (displayMode === "last-orders") ?
			<DataTable
				i18nPrefix="ordersheets.last-orders.datatable."
				items={orderSheetItems.list}
				columns={["whenCreated", "customerDisplayName", "product", "quantity" ] } 
				customDisplayColumns={[["whenCreated", "ZonedDateTime"]]}
				actions={[confirmOrderDeletion]}
				onError={context.onError}
				noItemsDisclaimer={true}
			/>
			: (displayMode === "price-list") ?
				<DataTable
					i18nPrefix="ordersheets.price-list.datatable."
					items={availableOrderedProducts}
					columns={["name", "description", "sellingPrice"]} 
					customDisplayColumns={[["sellingPrice", "Currency"]]}
					actions={[handleProductOrderAddition]}
					defaultAction={handleProductOrderAddition}
					onError={context.onError}
					noItemsDisclaimer={true}
				/>
			: null
			}
			
			{((displayMode === "account-orders" || displayMode === "last-orders") && !isRefreshing && !isArrayWithLength(orderSheetItems.list) && isToday()) ?
				 <Row className="mt-5">
					<Col className="text-center">
						<RippleButton style={rippleButtonStyle} text={t("ordersheets.list.lets-go-button")} onClick={handleToolbarOrderAddition}/>
					</Col>
				</Row>
				: null
			}
			
		</CustomContainer>
	);
}

export default OrderSheets;
