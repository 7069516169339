import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, useOutletContext  } from 'react-router-dom';
import log from 'loglevel';
import { Trans } from 'react-i18next';

import DataTable from "../components/DataTable";

import Badge from 'react-bootstrap/Badge';

import Loader from "../components/Loader";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import CustomContainer from "../components/CustomContainer";
import PagedList from "../components/PagedList";
import MediaFileDetailModal from "../components/MediaFileDetailModal";
import MediaFileFilterForm from "../components/MediaFileFilterForm";

import API from "../services/backend-api";

import { getAllFiles, isArrayWithLength } from "../helpers/commons";
import useSound from 'use-sound';

import deleteOrderAudio from '../audios/ok-houve-um-equivoco.mp3';

const MediaFiles = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [pagedMediaFiles, setPagedMediaFiles] = useState(null);
	const [mediaFileDetailModalShow, setMediaFileDetailModalShow] = useState(false);
	const [selectedMediaFile, setSelectedMediaFile] = useState(null);
	
	let context = useOutletContext();
	
	useEffect(() => {
		let isMounted = true; 
		
		let filter = {};
		filter.pageIndex = 0;
		filter.pageSize = 25;
		filter.sortBy = "file";
		filter.order = "asc";
		filter.filteredColumn = "file";
		filter.filter = "";
			
		pageMediaFiles(filter)
			.catch(error => { 
				log.error("Error Loading Initial Media Files List: ", error.message);
				context.onError(error);
			})
		 	.finally(() => {
		 		if (isMounted) setIsLoading(false);
		 	});
		 
		 return () => { isMounted = false };
		  
	}, []);
	
  
 
	
  const detailMediaFile = (media) => {
	  
	  setSelectedMediaFile(media);
	  setMediaFileDetailModalShow(true);
	      
	  /*if (media.type === "AUDIO") {
		  let audio = new Audio(media.url);
		  audio.play();
	  } else {
		  setSelectedMediaFile(media);
	      setMediaFileDetailModalShow(true);
	  }*/
  }
  
    const handleMediaFileDetailModalHide = () => {
	  setSelectedMediaFile(null); 
	  setMediaFileDetailModalShow(false);
  }
  
  const sortDataTable = (values) => {
	  values.pageIndex = 0;
	  pageMediaFiles(values)
		.catch(error => { 
			log.error("Error Sorting AccountActivities List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const mergeQueryParams = (currentPage, newValues) => {
	  
	  let queryParams = {};
	  
	  if (currentPage) {
		  queryParams.pageSize = currentPage.pageSize;
		  queryParams.sortBy = currentPage.sortBy;
		  queryParams.order = currentPage.order;
		  queryParams.filter = currentPage.filter;
		  queryParams.filteredColumn = currentPage.filteredColumn;
		  
		  queryParams.type = currentPage.type; 
		  queryParams.subType = currentPage.subType; 
	
	  }
	  
	  if (newValues) {
		  for(var property in newValues) {
				if(newValues.hasOwnProperty(property)) {
					queryParams[property] = newValues[property];
				}
			}
	  }
	  
	  return queryParams;
	  
  }
  
const updatePage = (values) => {
	  pageMediaFiles(values)
		.catch(error => { 
			log.error("Error Paginating Media Files List: ", error.message);
	 		context.onError(error);
		})
		
  }
  
  const filterMediaFiles = async (values) => {
	  try {
          return await pageMediaFiles(Object.assign({}, values, { pageIndex: 0 }));
      } catch (error) {
          log.error("Error Filtering Media Files : ", error.message);
          context.onError(error);
      }
		
  }
  
  const refreshPage = () => {
	  
	  let currentParams = {};
	  if (pagedMediaFiles) {
		  currentParams.pageIndex = pagedMediaFiles.pageIndex;
		  currentParams.pageSize = pagedMediaFiles.pageSize;
		  currentParams.sortBy = pagedMediaFiles.sortBy;
		  currentParams.order = pagedMediaFiles.order;
		  currentParams.filteredColumn = pagedMediaFiles.filteredColumn;
		  currentParams.filter = pagedMediaFiles.filter;  
	  }
		  
	  pageMediaFiles(currentParams)
		.catch(error => { 
	 		log.error("Error Refreshing Media Files List: ", error.message);
	 		context.onError(error);
		})	
  }  
 

  const pageMediaFiles = (values) =>
	new Promise((resolve, reject) => {
		//Clear Error
 		context.onError(null);
 		
 		try {
		   let allMediaFiles = [];
		   let queryParams = mergeQueryParams(pagedMediaFiles, values);
		
		   //Import all Memes
		   let memes = getAllFiles(require.context('../audios/memes', false, /\.(mp3|mp4|wav|ogg|webm|aac)$/));
		   Object.keys(memes).forEach((k) => { allMediaFiles.push(Object.assign({}, {file: k.toUpperCase(), type: "AUDIO", subType: "MEME", url: memes[k] })) });
				
		   //Import other Audio
		   let audioFiles = getAllFiles(require.context('../audios', false, /\.(mp3|mp4|wav|ogg|webm|aac)$/));
		   Object.keys(audioFiles).forEach((k) => { allMediaFiles.push(Object.assign({}, {file: k.toUpperCase(), type: "AUDIO", subType: "MEME", url: audioFiles[k] })) });
			
		   //Import all Mockups
		   let mockups = getAllFiles(require.context('../images/mockups', false, /\.(png|jpe?g|svg|webp)$/));
		   Object.keys(mockups).forEach((k) => { allMediaFiles.push(Object.assign({}, {file: k.toUpperCase(), type: "IMAGE", subType: "MOCKUP", url: mockups[k] })) });
		
		   //filter
		   let filteredMediaFiles = allMediaFiles.filter(item => {
		   		let filterResult = true;
		   		if (queryParams.type) filterResult = filterResult && (queryParams.type == item.type);
		   		if (queryParams.subType) filterResult = filterResult && (queryParams.subType == item.subType);
		   		if (queryParams.filteredColumn && queryParams.filter) filterResult = filterResult && (item.hasOwnProperty(queryParams.filteredColumn) && item[queryParams.filteredColumn].indexOf(queryParams.filter.toUpperCase()) >= 0);
		   		
		   		return filterResult;
		   });
		   
		   //sort
		   let sortedMediaFiles = filteredMediaFiles.sort((a,b) => {
			   	 
			   	 if (a.hasOwnProperty(queryParams.sortBy)) {	
					if (queryParams.order === "asc")
				 		return (a[queryParams.sortBy] < b[queryParams.sortBy]) ? -1 :  (a[queryParams.sortBy] > b[queryParams.sortBy]) ? 1 : 0;
				 	else 
				 		return (b[queryParams.sortBy] < a[queryParams.sortBy]) ? -1 :  (b[queryParams.sortBy] > a[queryParams.sortBy]) ? 1 : 0;
				 }
				 else {
					 return 0;
				 }
			});
		   
		   //page
		   let pagedItems = Object.assign({}, queryParams);
		   pagedItems.totalCount = sortedMediaFiles.length;
		   pagedItems.totalPageCount = Math.ceil(pagedItems.totalCount / pagedItems.pageSize) ;
		   pagedItems.hasPrev = (pagedItems.pageIndex > 0);
		   pagedItems.hasNext = (pagedItems.totalPageCount > pagedItems.pageIndex);
		   pagedItems.list = sortedMediaFiles.slice(pagedItems.pageIndex * pagedItems.pageSize, (pagedItems.pageIndex+1) * pagedItems.pageSize);
		   
		   setPagedMediaFiles(pagedItems);
		   resolve(pagedItems);
  
		} catch (error) {
  			reject(error);
		}
 		
	});
	
	if (isLoading) 
		return <Loader />
	
	if (!pagedMediaFiles) 
		return null;
		
	return (
		<CustomContainer>
			{(selectedMediaFile) && <MediaFileDetailModal
				show={mediaFileDetailModalShow}
				onHide={handleMediaFileDetailModalHide}
				size="md"
				item={selectedMediaFile}
				onError={context.onError}
			/>}
			<PagedList
				i18nPrefix="media-files.list."
				page={pagedMediaFiles}
				pageSizeOptions={[10,25,50,100]}
				filterFormComponent={MediaFileFilterForm}
				filterParameters={{ filter: pagedMediaFiles.filter, filteredColumn: pagedMediaFiles.filteredColumn }}
				onFilter={filterMediaFiles} 
				onError={context.onError}
				onSort={sortDataTable}
				onPage={updatePage}
				dataTableColumns={["file", "type", "subType"]}
				dataTableSortableColumns={["file"]}
				dataTableCustomDisplayColumns={[["type", "Enum"], ["subType", "Enum"]]}
				dataTableActions={[detailMediaFile]}		
				dataTableDefaultAction={detailMediaFile}
			/>
		</CustomContainer>
	);
}

export default MediaFiles;
