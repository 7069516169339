import React,{ useState, useEffect, Fragment} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import log from 'loglevel';

import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/pt';
//import 'moment/locale/en';
import moment from "moment";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV, faAngleLeft, faAngleRight, faList, faClock, faBirthdayCake, faPlusCircle, faDollarSign, faCalendar } from '@fortawesome/free-solid-svg-icons'

import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import FormCheck from 'react-bootstrap/FormCheck';
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
//import Container from 'react-bootstrap/Container'

import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'

import { useConfig } from "../services/use-config";

import { isArrayWithLength } from "../helpers/commons";

import '../styles/OrderSheet.css';

import { useAuth } from "../services/use-auth";

//import { DatePicker } from "react-tempusdominus-bootstrap";
//import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";


import DatePickerModal from "../components/DatePickerModal";

//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

	
const stickyHeaderStyle = { 
	position: "sticky", 
	top: 0, 
	backgroundColor: "#ffffff", 
	zIndex: 1,
	padding:"0.5rem",
	boxShadow: "inset 1px 1px #dee2e6, 1px 1px #dee2e6"
};
	
const DataTableHeader = props => {
	const [selectedAll, setSelectedAll] = useState(false);
	
	var rowItems = [];
	var columns = [];
	
	useEffect(() => {
		let isMounted = true; 
				
		if (isMounted) {
			setSelectedAll(false);
		}
		
		return () => { isMounted = false };
		  
	}, [props.items]);
	
	
	const renderTooltip = (text) => (
  		<Tooltip>
    		{text}
  		</Tooltip>
    );

	const handleProductDoubleClick = (event, product) => {
		if (event.detail === 2) 
			props.onProductOrderAddition({productId : product.productId, sellingPrice: product.sellingPrice}) 
    };

	const handleAccountDoubleClick = (event) => {
		if (event.detail === 2) 
			props.onProductOrderAddition({}) 
    };

	const handleMultipleSelectionChange = (e) => {
		setSelectedAll(e.target.checked);
		props.onMultipleSelectChange(e.target.checked);
    }
	
	if (isArrayWithLength(props.columns)) {
		if (isArrayWithLength(props.items)) {
			
			columns = props.columns.filter(column => {
				if (props.items[0].hasOwnProperty(column.name)) {
					return true;
				} else {
					log.warn("DataTableHeader: Column [", column.name,"] not found => Removed")
					return false;
				}
			})
			
		} else {
			columns = props.columns;
		}
	} else if (isArrayWithLength(props.items)) {
		for (var key in props.items[0]) {
			columns.push(key);
		}
	}

	rowItems = columns.map((column, index) => {
		return (<th scope="col" style={stickyHeaderStyle} key={index} className="text-center" onClick={(e) => { handleProductDoubleClick(e,column);} }>
					<OverlayTrigger overlay={renderTooltip(column.description)}>
						<span title={column.description}>{column.name}</span>
					</OverlayTrigger>
					{(props.mockups && props.mockups.get(column.productId)) ?
						<div><Image src={props.mockups.get(column.productId)} height="50px"/></div>
					: null
				}
				</th>)
	});

	
	if (isArrayWithLength(rowItems)) {
		
		//Add Account Display Name/Identification Column
		rowItems.unshift(<th scope="col" style={stickyHeaderStyle} key={"customerDisplayName"} onClick={(e) => { handleAccountDoubleClick(e);} }>
				{(props.i18nPrefix) ? <Trans i18nKey={props.i18nPrefix+"columns.customerDisplayName"} /> : "customer"}
			</th>)
				
		//Add MultipleSelectActions Column with DropDown options
		if (isArrayWithLength(props.multipleSelectActions)) {
		
			var actionItems = [];
		
			var selectedItems = props.items.filter((item, index) => props.selectedItems[index]);
		
			actionItems = props.multipleSelectActions.map((action, index) => 
				<Dropdown.Item key={index} onClick={() => {action(selectedItems, "multiple-action-"+index);}}><Trans i18nKey={props.i18nPrefix+"multiple-action-"+index} /></Dropdown.Item>
			)
		
			rowItems.unshift(<th key={"multipleSelectActions"} style={stickyHeaderStyle} className="align-top" >
				<FormCheck type="checkbox">
        			<FormCheck.Input type="checkbox" checked={selectedAll} onChange={handleMultipleSelectionChange} />
					{ (isArrayWithLength(selectedItems)) ?
						<Dropdown drop="left">
							<Dropdown.Toggle as={Button} className="p-0" bsPrefix="remove-split-button" variant="link" id="dropdown-actions" size="sm">
								<FontAwesomeIcon icon={faEllipsisV} />
							</Dropdown.Toggle>
		       				<Dropdown.Menu>
		       					{actionItems}
		       				</Dropdown.Menu>
		    			</Dropdown> : null
					}
				</FormCheck>
			</th>)
		}
		
		//Add Other Producs column in last
		rowItems.push(<th key={"otherProducts"} style={stickyHeaderStyle} className="text-center">
				{(props.i18nPrefix) ? <Trans i18nKey={props.i18nPrefix+"columns.otherProducts"} /> : "otherProducts"}
			</th>)
		
		//Add Actions column at the end
		if (isArrayWithLength(props.actions)) {
			rowItems.push(<th key={"actions"} style={stickyHeaderStyle} className="text-center">
				{(props.i18nPrefix) ? <Trans i18nKey={props.i18nPrefix+"columns.actions"} /> : "actions"}
			</th>)
		}
	
	}
	
	
	return (<thead style={stickyHeaderStyle} >
				<tr>{rowItems}</tr>
			</thead>)
}

const DataTableTotalRow = props => {
	var row = [];
	var columns = [];

	if (isArrayWithLength(props.columns)) {
		columns = props.columns.filter(column => {
			if (props.item.hasOwnProperty(column.name)) {
				return true;
			}
			
			return false;
		})
				
	} else {
		for (var key in props.item) {
			columns.push(key);
		}
	}
	
	
	row = columns.map((column, index) => {
		return(<td key={index} className="text-center" >{props.item[column.name]}</td>);	
	});
	
	//Add complementary Columns
	if (isArrayWithLength(row)) {
		
		//Add Account Display Name/Identification Column
		row.unshift(<td key="total" className="text-right">{(props.i18nPrefix) ? <Trans i18nKey={props.i18nPrefix+"columns.total"} /> : "total"}</td>);
		
		//Add MultipleSelectActions Check Column
		if (isArrayWithLength(props.multipleSelectActions)) {
			row.unshift(<td key={"multipleSelectActions"}></td>)
		}
		
		//Add Other Producs column in last
		row.push(<td key={"otherProducts"} className="text-center">
					{props.item["otherProducts"]}
				</td>)
		
	
		//Add Actions DropDown Column
		if (isArrayWithLength(props.actions)) {
				row.push(<td key={"actions"}></td>)
		}
	}
	
	return <tr className="font-weight-bold text-info">{row}</tr>;
}

const DataTableRow = props => {
	var row = [];
	var columns = [];
	
	const handleProductItemDoubleClick = (event, item, product ) => {
		if (event.detail === 2) 
			props.onAccountOrderAddition({accountNo: item.accountNo, customerDisplayName: item.customerDisplayName, productId : product.productId, sellingPrice: product.sellingPrice}) 
    };

	const handleAccountDoubleClick = (event, item) => {
		if (event.detail === 2) 
			props.onAccountOrderAddition({accountNo: item.accountNo, customerDisplayName: item.customerDisplayName}) 
    };

	const isBirthMonth = (accountNo) => {
		 
		return isArrayWithLength(props.birthdays) && props.birthdays.find(a => (a.accountNo === accountNo));
    };

	const isBirthDay = (accountNo, eventDate) => {
	
		return isArrayWithLength(props.birthdays) && props.birthdays.find(a => {
			return ((a.accountNo === accountNo) 
					&& (moment(a.birthDate, 'YYYY-MM-DD', true).date()) === moment(eventDate, 'YYYY-MM-DD', true).date())
		});
    };

	const renderOtherProductsPopover = (item) => (
  		<Popover id="popover-other-products">
    		<Popover.Title as="h3"><Trans i18nKey={props.i18nPrefix+"other-products-popover"}>Other Products Summary</Trans></Popover.Title>
    		<Popover.Content>
      			{Object.entries(item).map(([key, value],index) =>
					<div key={index}>{key}: {value}</div>
				)}
    		</Popover.Content>
  		</Popover>
    );

	if (isArrayWithLength(props.columns)) {
		columns = props.columns.filter(column => {
			if (props.item.hasOwnProperty(column.name)) {
				return true;
			}
			
			return false;
		})
				
	} else {
		for (var key in props.item) {
			columns.push(key);
		}
	}
	
	
	row = columns.map((column, index) => {
		return(<td key={index} className="text-center" onClick={(e) => { handleProductItemDoubleClick(e, props.item, column);} }>{props.item[column.name]}</td>);	
	});
	
	//Add complementary Columns
	if (isArrayWithLength(row)) {
		
		//Add Account Display Name/Identification Column
		row.unshift(<td key="customerDisplayName" onClick={(e) => { handleAccountDoubleClick(e, props.item);} }>{props.item["customerDisplayName"]} {isBirthMonth(props.item["accountNo"]) ? <FontAwesomeIcon color={isBirthDay(props.item["accountNo"], props.item["eventDate"]) ? "orange" : ""} icon={faBirthdayCake} /> : null}</td>);
		
		//Add MultipleSelectActions Check Column
		if (isArrayWithLength(props.multipleSelectActions)) {
			row.unshift(<td key={"multipleSelectActions"}>
				<FormCheck checked={(!props.selected) ? false : props.selected} onChange={(e) => {props.onSelect(props.index, e.target.checked);}} />
			</td>)
		}
		
		//Add Other Producs column in last
		row.push(<td key={"otherProducts"} className="text-center">
					{(props.item["otherProductsTotal"] > 0) ? 
						<OverlayTrigger trigger={'click'} rootClose overlay={renderOtherProductsPopover(props.item["otherProductsDetails"])}>
							<span>{props.item["otherProductsTotal"]}</span>
						</OverlayTrigger>
						: props.item["otherProductsTotal"]
					}
				</td>)
		
	
		//Add Actions DropDown Column
		if (isArrayWithLength(props.actions)) {
		
			var actionItems = [];
		
			actionItems = props.actions.map((action, index) => 
				<Dropdown.Item key={index} onClick={() => {action(props.item,"action-"+index);}}><Trans i18nKey={props.i18nPrefix+"action-"+index} values={{item: props.item}}/></Dropdown.Item>
			)
		
			row.push(<td key={"actions"} className="text-center">
				<Dropdown drop="left">
					<Dropdown.Toggle as={Button} bsPrefix="remove-split-button" variant="link" id="dropdown-actions" size="sm">
						<FontAwesomeIcon icon={faEllipsisV} />
					</Dropdown.Toggle>
		      	 	<Dropdown.Menu>
		       			{actionItems}
		       		</Dropdown.Menu>
		    	</Dropdown>
			</td>
			)
		}
	}
	
	
	
	return row;
}




const DataTable = props => {

	const [selectedItems, setSelectedItems] = useState([]);
	//const [columns, setColumns] = useState([]);
	const [accountItems, setAccountItems] = useState([]);
	const [totalProductsOrdered, setTotalProductsOrdered] = useState({});
	
	const config = useConfig();
	
	//Filter and Sort Active and Shelf Displayable Products
	const activeDisplayableProducts = props.products
			.filter(p =>(JSON.parse(p.available) && parseInt(p.shelfDisplayOrder) > 0))
			.sort((a,b) => parseInt(a.shelfDisplayOrder)-parseInt(b.shelfDisplayOrder));
			
	useEffect(() => {
		let isMounted = true; 
			
		if (isArrayWithLength(props.items) && isMounted) {
			setSelectedItems(Array.from({length: props.items.length}, (v, i) => false));
		}
		
		let accountAggregattedOrders =[];
		
		let totalProducts={}
		totalProducts.otherProducts = 0;
		props.products.forEach(product => {		
			if (activeDisplayableProducts.find(adp => (adp.name === product.name))){
				totalProducts[product.name] = 0;
			}
		});
					
		props.items.forEach((order, index) => {
		
			let found = accountAggregattedOrders.find(item => (item.accountNo === order.accountNo));
		
			if (found) {
				
				found.orders.push(order);
				
				found.customerBill += order.quantity*order.unitPrice;
				
				//If account found, we update product quantity accordinly
				if (activeDisplayableProducts.find(adp => (adp.name === order.product))){
					found[order.product] += order.quantity;
					totalProducts[order.product] += order.quantity;
				} else {
					found.otherProductsTotal += order.quantity;
					found.otherProductsDetails[order.product] =
						(found.otherProductsDetails[order.product]) ?
						found.otherProductsDetails[order.product] + order.quantity : order.quantity;
						
					totalProducts.otherProducts += order.quantity;
				}
			} else {
				//If account not found, we must create an entry
				let data = {};
				data.customerDisplayName = order.customerDisplayName;
				//data.customerBirthDate = order.customerBirthDate;
				data.eventDate = order.eventDate;
				data.accountNo = order.accountNo;
				data.otherProductsTotal = 0;
				data.otherProductsDetails = {};
				data.orders = [order];
				data.customerBill = 0;
				
				props.products.forEach(product => {			
					if (activeDisplayableProducts.find(adp => (adp.name === product.name))){
						data[product.name] = (product.name === order.product) ? order.quantity : 0;
						
						data.customerBill += (product.name === order.product) ? order.quantity*order.unitPrice : 0;
						
						totalProducts[product.name] += (product.name === order.product) ? order.quantity : 0;
					} else {
						if (product.name === order.product) {
							data.otherProductsTotal += order.quantity;
							data.otherProductsDetails[product.name] = order.quantity;
							data.customerBill += order.quantity*order.unitPrice;
							
							totalProducts.otherProducts += order.quantity;
							
						} 
					}
				});

				accountAggregattedOrders.push(data);
			}
		});
		
		
		
		if (isMounted) {
			setAccountItems(accountAggregattedOrders.sort((a, b) => a.customerDisplayName.localeCompare(b.customerDisplayName)));
			setTotalProductsOrdered(totalProducts);
		}
		
		
		return () => { isMounted = false };
		  
	}, [props.items]);
	

	if (!isArrayWithLength(accountItems)) {
		if (props.noItemsDisclaimer)
			return <div><Trans i18nKey={props.i18nPrefix+"no-items-disclaimer"} /></div>
		else
			return null
	}
	
	const handleMultipleSelectionChange = (checked) => {	
		setSelectedItems(Array.from({length: props.items.length}, (v, i) => checked));
    }

	const handleSelection = (index, checked) => {
		
		//alert(index + " " + checked)
		
		let s = selectedItems.map((item, i) => {
				return (index !== i) ? item : checked;	
		});
		
		setSelectedItems(s);
        
    }

	
	const items = accountItems.map((item, index) => {
	      return (
	        <tr key={index} className={(props.customRowStyle) ? props.customRowStyle(item) : ""}>
	           <DataTableRow 
	           		item={item}
					tooltipData={(isArrayWithLength(props.tooltipData) && props.tooltipData[index]) ? props.tooltipData[index] : null }
					tooltipTargetStyles={props.tooltipTargetStyles}
	           		//columns={props.products} 
					columns={activeDisplayableProducts}
					birthdays={props.birthdays}
	           		i18nPrefix={props.i18nPrefix} 
					handleTextOverflow={props.handleTextOverflow}
	           		actions={props.actions}
					multipleSelectActions={props.multipleSelectActions}
					onSelect={handleSelection}
					selected={selectedItems[index]}
					locale={config.preferredLanguage}
					numberFormat={config.numberFormat}
					onAccountOrderAddition={props.onAccountOrderAddition}
					index={index}
	           	/>
	        </tr>
	        )
	      })
	
	return (
		<Table striped /*responsive*/ bordered hover className={isArrayWithLength(props.sortableColumns) ? "table-sortable" : ""}>
			<DataTableHeader 
				items={accountItems}
				columns={activeDisplayableProducts}
				sortBy={props.sortBy} 
				order={props.order} 
				i18nPrefix={props.i18nPrefix} 
				actions={props.actions}
				multipleSelectActions={props.multipleSelectActions}
				onMultipleSelectChange={handleMultipleSelectionChange}
				onProductOrderAddition={props.onProductOrderAddition}
				selectedItems={selectedItems}
				sortDataTable={props.sortDataTable}
				showTotalRow={props.showTotalRow}
				mockups={props.mockups}
			/>
			<tbody>
			<Fragment>  
			{(props.showTotalRow) ? 
					<DataTableTotalRow 
						item={totalProductsOrdered} 
						columns={activeDisplayableProducts}
						actions={props.actions}
						multipleSelectActions={props.multipleSelectActions}
						i18nPrefix={props.i18nPrefix} 
					/> 
					: null
			  }
	          {items}
			</Fragment>	
	        </tbody>
	     </Table>
	);
}


const toolbarDisplayModes = [
	{
        key: 'account-orders',
        icon: faList
      },
		{
        key: 'last-orders',
        icon: faClock
      },
	  {
        key: 'price-list',
        icon: faDollarSign
      }
];

const Toolbar = props => {
	const [showDatePickerModal, setShowDatePickerModal] = useState(false);

	const { t } = useTranslation();
	const auth = useAuth();
	const config = useConfig();
	
	const isToday = () => {
	  
	  return (props.eventDate.format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD"));
    }  

	const renderTooltip = (text) => (
  		<Tooltip>
    		{text}
  		</Tooltip>
    );

	const handleDatePickerChange = (e) => {
    	setShowDatePickerModal(false);
    	props.onEventDateChange(moment(e, 'YYYY-MM-DD', true));
  	};

	const handleDatePickerModalHide = (e) => {
    	setShowDatePickerModal(false);
  	};
	
	return(
		<Fragment>
			{ (showDatePickerModal) ?
				<DatePickerModal
					show={showDatePickerModal}
        			onHide={handleDatePickerModalHide}
					format="DD/MM/YYYY"
					date={props.eventDate} 
					onChange={handleDatePickerChange} 
					locale={config.preferredLanguage}
					size="sm"
				/>
				: null
			}
			<Row className="mb-3">
				<Col md={"auto"}>
					<ButtonToolbar>
  						<ButtonGroup className="mr-2" >
    						<Button variant={props.variant} onClick={()=> { props.onEventDateChange(props.eventDate.clone().subtract(1, 'days'))}}><FontAwesomeIcon icon={faAngleLeft} /></Button>
							<Button variant={props.variant} onClick={()=> { props.onEventDateChange(props.eventDate.clone().add(1, 'days'))}}><FontAwesomeIcon icon={faAngleRight} /></Button>
  						</ButtonGroup>
  						<ButtonGroup className="mr-2">
    						<Button 
								variant={props.variant} 
								disabled={isToday()}
								onClick={()=> { props.onEventDateChange(moment(new Date()));}}
							>
								<Trans i18nKey={props.i18nPrefix+"today-button"} >Today</Trans>
							</Button>
  						</ButtonGroup>
						{ (auth.isAdmin()) ?
							<ButtonGroup className="mr-2">
    							<Button variant={props.variant}  onClick={()=> {setShowDatePickerModal(!showDatePickerModal); }}><FontAwesomeIcon icon={faCalendar} /></Button>
							</ButtonGroup>
							: null
						}
						{/*<ToggleButtonGroup type="checkbox" name="fontSize" value={props.fontSize} onChange={props.onFontSizeChange}>
          					
							<ToggleButton variant={props.variant} value={"lg"} >
								<FontAwesomeIcon size={isArrayWithLength(props.fontSize) ? "lg" : "sm"} icon={faFont} />
         				 	</ToggleButton>
						</ToggleButtonGroup>*/}
					</ButtonToolbar>
				
				</Col>
				<Col >
					<h3 className="d-flex justify-content-center"><Moment local format="DD/MMM/YYYY (ddd)">{props.eventDate}</Moment></h3>
				</Col>
				<Col md={"auto"}>
					<ButtonToolbar className="float-right">
						<ToggleButtonGroup className="mr-2" type="radio" name="displayMode" value={props.displayMode} onChange={props.onDisplayModeChange}>
							{toolbarDisplayModes.map((item, index) => (
          						<ToggleButton
            							key={index}
										variant={props.variant} 
            							value={item.key}
										disabled={(!isToday() && !auth.isAdmin())}
          							>
            							<OverlayTrigger overlay={renderTooltip(t(props.i18nPrefix+item.key+"-tooltip"))}>
											<FontAwesomeIcon icon={item.icon} />
										</OverlayTrigger>
         				 			</ToggleButton>
        					))}	
						</ToggleButtonGroup>
						<ButtonGroup  >
    						<Button variant={props.variant} disabled={(!isToday() && !auth.isAdmin())} onClick={()=> { props.onOrderAddition({eventDate: props.eventDate});}}><FontAwesomeIcon icon={faPlusCircle} /> <Trans i18nKey={props.i18nPrefix+"add-order-button"} >New Order</Trans></Button>
						</ButtonGroup>
					</ButtonToolbar>
				</Col>
			</Row>
		</Fragment>	
	);

}

const OrderSheet = { Toolbar, DataTable};

export default OrderSheet;
